<template>
  <NotFound v-if="isNotFound" />
  <div class="PaymentPlans" v-else>
    <ATable
      :columns="columns"
      :data-source="paymentPlans"
      :row-key="v => v._id"
      bordered
    >
      <template #contact="{ text }">
        <a>{{ text.fullName }} ({{ text.email }})</a>
      </template>
      <template #case="{ text }">
        <a>{{ text.name }} ({{ text.caseTypeName }})</a>
      </template>
      <template #user="{ text }">
        {{ text.firstName }} {{ text.lastName }}
      </template>
      <template #action="{}">
        <span>
          <a @click="showModal">View</a>
        </span>
      </template>
    </ATable>

    <AModal
      v-model:visible="visible"
      title="Viewer"
      @ok="handleOk"
      width="800px"
    >
      <ViewerPdf />

      <template #footer>
        <a-button key="back" @click="handleCancel"> Return </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleOk"
        >
          Download
        </a-button>
      </template>
    </AModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import NotFound from '@/views/NotFound.vue';
import { PaymentPlanService } from '@/services';
import ViewerPdf from '@/components/ViewerPdf.vue';
import { useGlobalProps } from '@/composables';

export default defineComponent({
  components: { NotFound, ViewerPdf },
  props: {
    caseId: String,
  },
  setup(props) {
    /** State */
    const isNotFound =
      (props.caseId && /^([0-9])*$/.test(props.caseId) === false) ||
      Number(props.caseId) <= 0;

    const paymentPlans: any = ref<any[]>([]);

    /** Datatable columns */
    const columns = [
      {
        title: 'Contact',
        dataIndex: 'contact',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.invoiceNumber - b.invoiceNumber,
        slots: { customRender: 'contact' },
      },
      {
        title: 'Case',
        dataIndex: 'case',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.dueDate - b.dueDate,
        slots: { customRender: 'case' },
      },
      {
        title: 'User',
        dataIndex: 'user',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.amount - b.amount,
        slots: { customRender: 'user' },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.dueDate - b.dueDate,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.amount - b.amount,
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
      },
    ];

    const loading = ref<boolean>(false);
    const visible = ref<boolean>(false);

    const { setForm } = useGlobalProps();

    /** Methods */

    const getPaymentPlans = async () => {
      try {
        const data = await PaymentPlanService.getCase(Number(props.caseId));

        paymentPlans.value = data;

        setForm(data);
      } catch (error) {
        console.log(error);
      }
    };

    getPaymentPlans();

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = () => {
      loading.value = true;
      setTimeout(() => {
        visible.value = false;
        loading.value = false;
      }, 3000);
    };

    const handleCancel = () => {
      visible.value = false;
    };

    return {
      isNotFound,
      columns,
      paymentPlans,
      loading,
      visible,
      showModal,
      handleOk,
      handleCancel,
    };
  },
});
</script>

<style lang="scss" scoped>
.PaymentPlans {
  margin: 0 auto;
}
</style>
