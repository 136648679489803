
import { defineComponent, ref } from 'vue';
import NotFound from '@/views/NotFound.vue';
import { PaymentPlanService } from '@/services';
import ViewerPdf from '@/components/ViewerPdf.vue';
import { useGlobalProps } from '@/composables';

export default defineComponent({
  components: { NotFound, ViewerPdf },
  props: {
    caseId: String,
  },
  setup(props) {
    /** State */
    const isNotFound =
      (props.caseId && /^([0-9])*$/.test(props.caseId) === false) ||
      Number(props.caseId) <= 0;

    const paymentPlans: any = ref<any[]>([]);

    /** Datatable columns */
    const columns = [
      {
        title: 'Contact',
        dataIndex: 'contact',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.invoiceNumber - b.invoiceNumber,
        slots: { customRender: 'contact' },
      },
      {
        title: 'Case',
        dataIndex: 'case',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.dueDate - b.dueDate,
        slots: { customRender: 'case' },
      },
      {
        title: 'User',
        dataIndex: 'user',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.amount - b.amount,
        slots: { customRender: 'user' },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.dueDate - b.dueDate,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.amount - b.amount,
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
      },
    ];

    const loading = ref<boolean>(false);
    const visible = ref<boolean>(false);

    const { setForm } = useGlobalProps();

    /** Methods */

    const getPaymentPlans = async () => {
      try {
        const data = await PaymentPlanService.getCase(Number(props.caseId));

        paymentPlans.value = data;

        setForm(data);
      } catch (error) {
        console.log(error);
      }
    };

    getPaymentPlans();

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = () => {
      loading.value = true;
      setTimeout(() => {
        visible.value = false;
        loading.value = false;
      }, 3000);
    };

    const handleCancel = () => {
      visible.value = false;
    };

    return {
      isNotFound,
      columns,
      paymentPlans,
      loading,
      visible,
      showModal,
      handleOk,
      handleCancel,
    };
  },
});
