<template>
  <NotFound v-if="isNotFound" />

  <div v-else class="ViewerPaymentPlan">
    <ViewerPdf :viewer="true" #default="{ download }">
      <div class="ViewerPaymentPlan__actions">
        <AButton @click="onClose" style="margin-right: 8px">
          Close
          <CloseOutlined />
        </AButton>
        <AButton
          style="margin-right: 8px"
          type="primary"
          @click="onDonwload(download)"
        >
          Download
          <DownloadOutlined />
        </AButton>
      </div>
    </ViewerPdf>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import NotFound from '@/views/NotFound.vue';
import { PaymentPlanService, UserService } from '@/services';
import ViewerPdf from '@/components/ViewerPdf.vue';
import { useGlobalProps } from '@/composables';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  components: { NotFound, ViewerPdf, CloseOutlined, DownloadOutlined },
  props: {
    paymentPlan: String,
  },
  setup(props) {
    /** State */
    const isNotFound =
      props.paymentPlan && /^([0-9])*$/.test(props.paymentPlan) === true;

    const { setForm, setUser, setTenantPictureBase64 } = useGlobalProps();

    const paymentPlanId = props.paymentPlan ? props.paymentPlan : '';

    /** Methods */
    window.addEventListener('message', e => {
      const primaUrl = process.env.VUE_APP_PRIMA_URL.replace('com/', 'com');
      // Get the sent data
      if (e.origin === primaUrl) {
        const data = e.data;

        setTenantPictureBase64(data);
      }

      // If you encode the message in JSON before sending them,
      // then decode here
      // const decoded = JSON.parse(data);
    });

    const getPaymentPlan = async () => {
      try {
        const data: any = await PaymentPlanService.getId(paymentPlanId);

        data[0].invoices = data[0].bills;
        data[0].totalPayment = data[0].bills.reduce(
          (sum: number, value: any) =>
            typeof value.invoiceTotal == 'number'
              ? sum + value.invoiceTotal
              : sum,
          0,
        );

        setForm(data[0]);
      } catch (error) {
        console.log(error);
      }
    };

    getPaymentPlan();

    const getUser = async () => {
      try {
        // Get user
        const data = await UserService.get();
        setUser(data);
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(() => {
      getUser();
    });

    const onDonwload = async (download: () => Promise<any>) => {
      await download();
    };

    /** Hanlder for close stepper */
    const onClose = () => {
      window.top.postMessage('close viewer pdf', process.env.VUE_APP_PRIMA_URL);
    };

    return {
      isNotFound,
      onDonwload,
      onClose,
    };
  },
});
</script>

<style lang="scss" scoped>
.ViewerPaymentPlan {
  margin: 0 auto;
  padding: 1rem;

  &__actions {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
